<template>
  <div class="member-shop-point-exchange-prodct-edit-info-block">
    <p class="card-title">新增兌換商品</p>

    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="商品性質" prop="type" data-permit="admin.classTicket.page">
        <BaseElRadioGroup v-if="!productId && !isEmpty(displayProductTypeOptions)" v-model="formData.type" :disabled="!!productId" @change="onTypeChange">
          <BaseElRadio
            v-for="type in displayProductTypeOptions"
            :key="type.value"
            :label="type.value"
          >
            {{ type.label }}
          </BaseElRadio>
        </BaseElRadioGroup>

        <el-alert
          v-if="!productId && isEmpty(displayProductTypeOptions)"
          title="提醒："
          type="warning"
          show-icon
          center
          :closable="false"
        >
          <p>此店家目前無開啟兌換商品相關的模組，因此無法設定兌換商品。 如有相關需求請與歐巴小幫手聯繫，以取得協助。</p>
        </el-alert>

        <p v-if="productId">{{ get(productTypeConfig, `${formData.type}.label`) }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="圖片" prop="image" class="form-relative-label others">
        <UploadButton
          cyUploadBtn="service-img-upload-btn"
          cyUploadedImg="service-img-uploaded"
          :img="formData.image"
          :isAvatar="true"
          @change="loadImg"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.type === 'classTicket' " label="是否永豐價保" prop="isPaymentPromise">
        <BaseElRadioGroup v-if="!productId" v-model="formData.isPaymentPromise" :disabled="!!productId" @change="formData.classTicket = null, onPaymentPromiseChange()">
          <BaseElRadio :label="true">是</BaseElRadio>
          <BaseElRadio :label="false">否</BaseElRadio>
        </BaseElRadioGroup>
        <p v-else>{{ formData.isPaymentPromise ? '是' : '否' }}</p>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.isPaymentPromise && formData.type === 'classTicket'" label="支付方式" prop="promisePaymentConfigId">
        <BaseElSelect
          v-model="formData.promisePaymentConfigId"
          :disabled="!!productId && formData.type === 'classTicket'"
          @change="updatePromisePayment"
        >
          <BaseElSelectOption
            v-for="type in displayPaymentTypeOptions"
            :key="type.id"
            :label="type.name"
            :value="type.id"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.type === 'classTicket'" label="堂票綁定" prop="classTicket">
        <BaseElSelect
          v-if="features.useClassTicket"
          v-model="formData.classTicket"
          popper-class="select-popper"
          placeholder="選擇堂票"
          :disabled="!!productId && formData.type === 'classTicket'"
          @visible-change="showClassTicketSelectModal = true"
        >
          <BaseElSelectOption
            :label="findCouponName(formData.classTicket, 'classTicket')"
            :value="formData.classTicket"
          />
        </BaseElSelect>
        <p v-else class="text-danger">未開啟堂票模組故無法顯示</p>
      </BaseElFormItem>
      <BaseElFormItem
        v-if="formData.type === 'coupon'"
        label="票券綁定"
        prop="couponId"
      >
        <BaseElSelect
          v-if="features.useCoupon || features.useCouponExchange || features.useOpentix"
          v-model="formData.couponId"
          popper-class="select-popper"
          placeholder="選擇票券"
          :disabled="!!productId && formData.type === 'coupon'"
          @visible-change="showCouponSelectModal = true"
        >
          <BaseElSelectOption
            :label="findCouponName(formData.couponId, 'coupon')"
            :value="formData.couponId"
          />
        </BaseElSelect>
        <p v-else class="text-danger">未開啟票券模組故無法顯示</p>
      </BaseElFormItem>
      <BaseElFormItem label="名稱" prop="name">
        <BaseElInput v-model="formData.name" placeholder="商品名稱" />
      </BaseElFormItem>
      <BaseElFormItem label="公開顯示" prop="isPublic">
        <BaseElSwitch
          v-model="formData.isPublic"
          inactive-text="關閉"
          active-text="開啟"
          @change="checkExchangeProductExp"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.type === 'cashback'" label="回饋金面額" prop="cashbackAmount">
        <BaseElInput v-model="formData.cashbackAmount" placeholder="請輸入購買後實際回饋的金額" />
      </BaseElFormItem>
      <BaseElFormItem
        v-if="formData.type === 'wallet'"
        label="回饋金金額"
        prop="walletAmount"
        :disabled="!!productId && formData.type === 'wallet'"
      >
        <BaseElInput v-if="features.useChashback" v-model="formData.walletAmount" placeholder="請輸入購買後實際儲值的金額" />
        <p v-else class="text-danger">未開啟回饋金模組故無法顯示</p>
      </BaseElFormItem>

      <BaseElFormItem label="商品描述" prop="description">
        <quillEditor v-model="formData.description" :options="editorOption" />
      </BaseElFormItem>
      <BaseElFormItem label="注意事項" prop="notice">
        <quillEditor v-model="formData.notice" :options="editorOption" />
      </BaseElFormItem>
      <BaseElFormItem label="排序" prop="order">
        <BaseElInput v-model="formData.order" placeholder="請輸入排序" />
      </BaseElFormItem>
    </BaseElForm>

    <ImageCropper
      v-if="modal.imageCropper"
      :image="formData.image"
      @uploaded="getImage"
      @close="modal.imageCropper = false"
    />

    <CouponAdvanceSelect
      v-if="showCouponSelectModal"
      :data="couponList"
      :typeOptions="availableCouponType"
      :disabledExp="true"
      @confirm="onAddCoupon"
      @close="showCouponSelectModal = false"
    />
    <ClassTicketAdvanceSelect
      v-if="showClassTicketSelectModal"
      :data="classTicketDisplayFilter"
      :typeOptions="couponTypeConfig"
      :selectedData="formData.classTicket ? [formData.classTicket] : []"
      :disabledExp="true"
      @confirm="onAddClassTicket"
      @close="showClassTicketSelectModal = false"
    />

    <WarningDialog
      v-if="modal.expWarn"
      title="提醒：此商品已經過期"
      hideCancel
      btnString="關閉"
      @close="modal.expWarn = false"
      @confirm="modal.expWarn = false"
    >
      <template slot="body">
        <div>
          無法將已經過期的商品公開顯示於點數兌換中心。
        </div>
      </template>
    </WarningDialog>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, nextTick, ref, watch } from 'vue'
import { ellipsisText, getAllDataFromApi } from '@/utils/helper'
import { useEditor } from '@/use/editor'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { productTypeConfig } from '@/config/memberPoint'
import { orderPaymentTypeConfig } from '@/config/memberShop'
import { GetMemberShopPaymentConfig } from '@/api/memberShop'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { useRoute } from 'vue-router/composables'
import FormsContext from '../context'
import { isDigitRules, noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { omit, omitBy, get, isEmpty, filter, isNull, find } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { useVModel } from '@vueuse/core'

import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import ClassTicketAdvanceSelect from '@/components/Select/ClassTicketAdvanceSelect.vue'
import { couponTypeConfig } from '@/config/couponExchange'
import { FindCoupon, GetCoupon, GetCouponCount } from '@/api/lottery/coupon'
import store from '@/store'
import { GetClassTicket } from '@/api/classTicket'
import { useFetch } from '@/use/fetch'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'MemberShopPointExchangeProdctEditInfoBlock',
  components: {
    UploadButton,
    ImageCropper,
    quillEditor,
    CouponAdvanceSelect,
    ClassTicketAdvanceSelect,
    WarningDialog,
  },
  props: {
    productData: { type: Object, default: () => ({}) },
    context: { type: Object, default: () => ({}) },
    isPaymentPromise: { type: Boolean, default: false },
  },
  emits: ['update:context', 'update:isPaymentPromise'],
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const { editorOption } = useEditor()
    const { fetchAll } = useFetch()
    const route = useRoute()
    const productId = computed(() => route.params.id)
    const modal = reactive({
      imageCropper: false,
      expWarn: false,
    })
    const shopId = computed(() => store.getters.shop)
    const availableCouponType = computed(() => {
      const omitList = []
      if (!checkAction('admin.coupon.page'))omitList.push('coupon')
      if (!checkAction('admin.couponExchange.page'))omitList.push('couponExchange')
      if (!checkAction('admin.couponOpentix.page'))omitList.push('couponOpentix')
      if (!checkAction('admin.couponPospal.find'))omitList.push('pospal')
      return omit(couponTypeConfig, omitList)
    })
    const syncContext = useVModel(props, 'context', emit)
    const syncIsPaymentPromise = useVModel(props, 'isPaymentPromise', emit)
    const formRef = ref(null)
    const formData = reactive({
      type: 'classTicket',
      isPaymentPromise: false,
      image: null,
      name: null,
      isPublic: true,
      description: null,
      notice: null,
      order: 100,
      classTicket: null,
      walletAmount: null,
      // pointExchangeAmount: null,
      cashbackAmount: null,
      couponId: null,
      promisePaymentConfigId: null,
    })
    const shopPaymentConfig = ref([])
    const formRules = computed(() => {
      const rules = {
        type: [noEmptyRules()],
        // image: [noEmptyRules()],
        isPublic: [noEmptyRules()],
        name: [noEmptyRules()],
        description: [noEmptyRules()],
        notice: [noEmptyRules()],
        order: [noEmptyRules(), isDigitRules()],
      }
      if (formData.type === 'classTicket') {
        rules.classTicket = [noEmptyRules()]
        rules.isPaymentPromise = [noEmptyRules()]
        rules.promisePaymentConfigId = [noEmptyRules()]
      }
      if (formData.type === 'wallet') rules.walletAmount = [noEmptyRules(), isDigitRules()]
      if (formData.type === 'cashback') {
        rules.pointExchangeAmount = [noEmptyRules(), isDigitRules()]
        rules.cashbackAmount = [noEmptyRules(), isDigitRules()]
      }
      if (formData.type === 'coupon') rules.couponId = [noEmptyRules()]
      return rules
    })
    const features = reactive({
      useChashback: computed(() => checkAction('admin.shopCashback.page')),
      useClassTicket: computed(() => checkAction('admin.classTicket.page')),
      useCouponExchange: computed(() => checkAction('admin.couponExchange.page')),
      useCoupon: computed(() => checkAction('admin.coupon.page')),
      useOpentix: computed(() => checkAction('admin.couponOpentix.page')),
    })
    const showCouponSelectModal = ref(false)
    const showClassTicketSelectModal = ref(false)
    const couponList = ref([])
    const classTicketList = ref([])
    const displayPaymentTypeOptions = computed(() => {
      return filter(shopPaymentConfig.value, item => item.type === 'sinoPacEpos' && item.limitProductType === 'classTicket' && item.isPaymentPromise && item.enable)
    })
    const displayProductTypeOptions = computed(() => {
      const omitList = []
      if (!features.useChashback)omitList.push('cashback')
      if (!features.useClassTicket)omitList.push('classTicket')
      if (!features.useCoupon && !features.useCouponExchange && !features.useOpentix)omitList.push('coupon')
      return omit(productTypeConfig, omitList)
    })
    const compactData = computed(() => {
      const data = omitBy(omit(formData, ['image']), i => i === null)
      data.imageId = get(formData.image, 'id') || undefined
      if (['coupon', 'cashback'].includes(data.type)) data.isPaymentPromise = false
      if (data.pointExchangeAmount) data.pointExchangeAmount = Number(data.pointExchangeAmount)
      if (data.walletAmount) data.walletAmount = Number(data.walletAmount)
      return { ...data }
    })

    const syncData = () => {
      const data = props.productData
      formData.type = data.type
      formData.isPaymentPromise = data.isPaymentPromise
      formData.promisePaymentConfigId = data.PromisePaymentConfigId
      formData.image = data.Image
      formData.name = data.name
      formData.isPublic = data.isPublic
      formData.description = data.description
      formData.notice = data.notice
      formData.order = data.order
      formData.classTicket = data.ClassTicketId
      formData.walletAmount = data.walletAmount
      // formData.pointExchangeAmount = data.pointExchangeAmount
      formData.cashbackAmount = data.cashbackAmount
      formData.couponId = data.CouponId
    }

    const classTicketDisplayLabel = (item, index) => {
      return `${index + 1}. ${ellipsisText(item.name, 35)}`
    }
    const classTicketDisplayFilter = computed(() => {
      if (!formData.isPaymentPromise) {
        return classTicketList.value
      }
      if (!isNull(formData.promisePaymentConfigId)) {
        // const paymentData = find(shopPaymentConfig.value, { id: formData.promisePaymentConfigId })
        // const promisePeriodDays = get(paymentData, 'PaymentPromiseConfig.promisePeriodDays')
        // return classTicketList.value.filter(i => i.isValuable && !isNull(i.expDay) && i.expDay === promisePeriodDays)
        return classTicketList.value.filter(i => i.isValuable && !i.isExp)
      }
      return []
    })

    const onTypeChange = (type) => {
      formUtils.clearValidate(formRef.value)
      syncContext.value.type = type
    }
    const onPaymentPromiseChange = () => {
      syncIsPaymentPromise.value = formData.isPaymentPromise
    }
    const updatePromisePayment = () => {
      formData.classTicket = null
    }

    const onClassTicketChange = (data) => {
      if (!formData.image) formData.image = data.Image
    }

    const getImage = (data) => {
      formData.image = data
      modal.imageCropper = false
    }
    const loadImg = (img) => {
      formData.image = img
      modal.imageCropper = true
    }

    watch(formData, () => {
      FormsContext.setFormData('info', { ...compactData.value })
    })

    const checkExchangeProductExp = (isPublic) => {
      if (!isPublic) return
      const productType = get(props.productData, 'type')
      let exchangeProduct
      if (productType === 'classTicket') exchangeProduct = get(props.productData, 'ClassTicket')
      else if (productType === 'coupon') exchangeProduct = get(props.productData, 'Coupon')

      const expDate = get(exchangeProduct, 'specifyExpiredDate') || get(exchangeProduct, 'expAt')
      if (!expDate) return

      if (dayjs(expDate).isBefore(dayjs())) {
        modal.expWarn = true
        formData.isPublic = false
      }
    }
    const getMemberShopPaymentConfig = async () => {
      const [res, err] = await GetMemberShopPaymentConfig({
        shopId: shopId.value,
        start: 0,
      })
      if (err) window.$message.error(err)
      shopPaymentConfig.value = res
    }
    onMounted(async () => {
      await nextTick()
      await getMemberShopPaymentConfig()
      FormsContext.setFormRef('info', formRef.value)

      if (get(props.productData, 'id')) syncData()
      if (features.useCoupon || features.useCouponExchange || features.useOpentix) getAllCoupon()
      if (features.useClassTicket) getAllClassTicket()
    })

    const getCouponCount = async () => {
      const [res, err] = await GetCouponCount({ shopId: shopId.value, type: 'all' })
      if (err) throw err
      return res.count
    }

    const getAllCoupon = async () => {
      let max
      try {
        max = await getCouponCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
        type: 'all',
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetCoupon,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      couponList.value = res
    }
    const getAllClassTicket = async () => {
      await fetchAll(GetClassTicket, { shopId: shopId.value }, (res) => {
        classTicketList.value = res
      })
    }

    const onAddCoupon = (coupon) => {
      if (coupon.length > 0) {
        formData.couponId = coupon[0]
        FormsContext.setTempData('couponData', find(couponList.value, { id: coupon[0] }))
      }
    }
    const onAddClassTicket = (classTicket) => {
      if (classTicket.length > 0) {
        formData.classTicket = classTicket[0]
        FormsContext.setTempData('couponData', find(classTicketList.value, { id: classTicket[0] }))
      }
    }

    const findCouponName = (idx, type) => {
      let dataList
      if (type === 'coupon') dataList = couponList.value
      if (type === 'classTicket') dataList = classTicketList.value
      const coupon = dataList.find(({ id }) => id === idx)
      if (coupon) return coupon.name
      return ''
    }

    return {
      get,
      formRef,
      formData,
      formRules,
      editorOption,
      classTicketDisplayLabel,
      classTicketDisplayFilter,
      onClassTicketChange,
      getImage,
      loadImg,
      modal,
      productTypeConfig,
      productId,
      onTypeChange,
      displayProductTypeOptions,
      couponTypeConfig,
      showCouponSelectModal,
      findCouponName,
      couponList,
      onAddCoupon,
      showClassTicketSelectModal,
      classTicketList,
      onAddClassTicket,
      checkExchangeProductExp,
      features,
      isEmpty,
      displayPaymentTypeOptions,
      shopPaymentConfig,
      onPaymentPromiseChange,
      updatePromisePayment,
      syncIsPaymentPromise,
      availableCouponType,
    }
  },
})
</script>

<style lang="postcss" scoped>
.select-popper {
  @apply hidden;
}

::v-deep .el-alert__content{
  padding: 0 20px;
}

::v-deep .el-alert__description{
  @apply leading-[1];
}
</style>
